import { putRequest, getRequest, deleteRequest } from "./request";
import {
  PROPERTY_ATTORNEYS_ENDPOINT,
  PROPERTY_ATTORNEYS_NEXT_PAGE_ENDPOINT,
} from "../constants/routes";

export const submitPropertyAttorneys = (config) =>
  putRequest(PROPERTY_ATTORNEYS_ENDPOINT, config);

export const getPropertyAttorneys = (config) =>
  getRequest(PROPERTY_ATTORNEYS_ENDPOINT, config);

export const removePropertyAttorneyEndpoint = (config) =>
  deleteRequest(PROPERTY_ATTORNEYS_ENDPOINT, config);

export const getPropertyAttorneysNextPage = (config) =>
  putRequest(PROPERTY_ATTORNEYS_NEXT_PAGE_ENDPOINT, config);
