export const fetchPropertyAttorneys = () => ({
  type: FETCH_PROPERTY_ATTORNEYS_TYPE,
});

export const propertyAttorneysNextPage = () => ({
  type: PROPERTY_ATTORNEYS_NEXT_PAGE_TYPE,
});

export const openModalPropertyAttorney = (propertyAttorney = null) => ({
  type: OPEN_MODAL_PROPERTY_ATTORNEY_TYPE,
  payload: { propertyAttorney },
});

export const removePropertyAttorney = (propertyAttorney) => {
  return {
    type: REMOVE_PROPERTY_ATTORNEY_TYPE,
    payload: { propertyAttorney },
  };
};

export const propertyAttorneyUpdatedSuccessfully = (propertyAttorney) => ({
  type: UPDATE_PROPERTY_ATTORNEY_SUCCESSFULLY_TYPE,
  payload: { propertyAttorney },
});

export const FETCH_PROPERTY_ATTORNEYS_TYPE = "FETCH_PROPERTY_ATTORNEYS";
export const UPDATE_PROPERTY_ATTORNEYS_TYPE = "UPDATE_PROPERTY_ATTORNEYS";
export const OPEN_MODAL_PROPERTY_ATTORNEY_TYPE = "OPEN_MODAL_PROPERTY_ATTORNEY";
export const REMOVE_PROPERTY_ATTORNEY_TYPE = "REMOVE_PROPERTY_ATTORNEY";
export const UPDATE_PROPERTY_ATTORNEY_SUCCESSFULLY_TYPE =
  "UPDATE_PROPERTY_ATTORNEY_SUCCESSFULLY";
export const PROPERTY_ATTORNEYS_NEXT_PAGE_TYPE = "PROPERTY_ATTORNEYS_NEXT_PAGE";
