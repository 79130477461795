import React, { useEffect } from "react";
import { connect } from "react-redux";

import { propertyAttorneysNextPage } from "../../../actions/property-attorneys";
import { propertyAttorneysPageProps } from "../../../selectors/property-attorneys";

import PropertyAttorneysForm from "../../../components/forms/your-arrangements/PropertyAttorneysForm";
import { WISHES_CEREMONY_PATH } from "../../../constants/routes";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { PROPERTY_ATTORNEYS_FORM_ID } from "../../../constants/forms";
import { getFormData } from "../../../actions/forms";

const PropertyAttorneyPage = ({
  getFormData,
  propertyAttorneysNextPage,
  hasLoaded,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(PROPERTY_ATTORNEYS_FORM_ID);
  }, [getFormData]);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <PropertyAttorneysForm
      backLink={WISHES_CEREMONY_PATH}
      onSubmit={() => propertyAttorneysNextPage()}
      {...restOfProps}
    />
  );
};

export default connect(propertyAttorneysPageProps, {
  getFormData,
  propertyAttorneysNextPage,
})(PropertyAttorneyPage);
